:root {
  --background: #fff;
  --offset: #eef0eb;
  --primary: #946e83;
  --primary-highlight: #996381;
  --accent-green: #ddf8e8;
  --accent-dark-green: #3b5249;
  --accent-purple: #b4a6ab;
  --accent-dark: #615055;
}

/* cinzel-regular - latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../public/assets/fonts/cinzel-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/assets/fonts/cinzel-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/assets/fonts/cinzel-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/assets/fonts/cinzel-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../public/assets/fonts/cinzel-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/assets/fonts/cinzel-v19-latin-regular.svg#Cinzel') format('svg'); /* Legacy iOS */
}
/* cinzel-700 - latin */
@font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../public/assets/fonts/cinzel-v19-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/assets/fonts/cinzel-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/assets/fonts/cinzel-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/assets/fonts/cinzel-v19-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../public/assets/fonts/cinzel-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/assets/fonts/cinzel-v19-latin-700.svg#Cinzel') format('svg'); /* Legacy iOS */
}

/* inter-regular - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../public/assets/fonts/inter-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/assets/fonts/inter-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/assets/fonts/inter-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/assets/fonts/inter-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../public/assets/fonts/inter-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/assets/fonts/inter-v12-latin-regular.svg#Inter') format('svg'); /* Legacy iOS */
}
/* inter-700 - latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../public/assets/fonts/inter-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../public/assets/fonts/inter-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../public/assets/fonts/inter-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../public/assets/fonts/inter-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../public/assets/fonts/inter-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../public/assets/fonts/inter-v12-latin-700.svg#Inter') format('svg'); /* Legacy iOS */
}

* {
  font-family: "Inter" !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Content {
  position: relative;
  min-height: 100vh;
}

.ContentWrapper {
  position: relative;
}

.MuiButton-root {
  border-radius: 0px !important;
}

.MuiButton-containedSecondary {
  background-color: var(--primary);
}

.MuiButton-outlinedSecondary {
  border: 1px solid var(--primary);
  color: var(--primary);
}

.navbar-toggler {
  background-color: transparent;
  /*Force pointer*/
  cursor: pointer;

  /*Center icon*/
  /* display: flex; */
  justify-content: center;
  align-items: center;

  /*Button Size*/
  height: 1.5em;
  width: 1.5em;

  /*Keep on top of elements*/
  z-index: 9999;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(2),
.navbar-toggler span:nth-child(3) {
  border-radius: 0.5rem;
  display: block;

  /*Icon Size*/
  width: 1.5em;
  height: 0.1em;

  /*Animation Transition*/
  transition: .5s;
  position: absolute;
}

.navbar-toggler span:nth-child(3) {
  bottom: 0.2em;
}
.navbar-toggler span:nth-child(1) {
  top: 0.2em;
}

.rotate-cc span:nth-child(2) {
  background-color: transparent !important;
}

.rotate-cc span:nth-child(1) {
  top: .7em;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.rotate-cc span:nth-child(3) {
  bottom: .7em;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
} 

.banner {
  position: relative;
  height: 75vh;
  max-height: 900px;
  width: 100%;
}
.banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.banner .banner-text {
  display: block;
  color: #fff;
  position: absolute;
  top: 20%;
  width: 100%;
}

.card {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.card-text {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='24' viewBox='0 0 88 24'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='autumn' fill='%239C92AC' fill-opacity='0.1'%3E%3Cpath d='M10 0l30 15 2 1V2.18A10 10 0 0 0 41.76 0H39.7a8 8 0 0 1 .3 2.18v10.58L14.47 0H10zm31.76 24a10 10 0 0 0-5.29-6.76L4 1 2 0v13.82a10 10 0 0 0 5.53 8.94L10 24h4.47l-6.05-3.02A8 8 0 0 1 4 13.82V3.24l31.58 15.78A8 8 0 0 1 39.7 24h2.06zM78 24l2.47-1.24A10 10 0 0 0 86 13.82V0l-2 1-32.47 16.24A10 10 0 0 0 46.24 24h2.06a8 8 0 0 1 4.12-4.98L84 3.24v10.58a8 8 0 0 1-4.42 7.16L73.53 24H78zm0-24L48 15l-2 1V2.18A10 10 0 0 1 46.24 0h2.06a8 8 0 0 0-.3 2.18v10.58L73.53 0H78z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.card-text::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0) 100%
  );
}

.appointment-card-container {
  position: relative;
  height: 50vh;
}

.appointment-card {
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  /* box-shadow: 25px 25px 20px var(--accent-dark-green); */
}

.footerLeft {
  text-align: left;
  padding-left: 20px;
}

.footerRight {
  text-align: right;
  padding-right: 20px;
}

.calendly-popup-close {
  z-index: 999 !important;
}

.calendly-popup {
  max-height: 100% !important;
}

.service-banner {
  position: relative;
  height: 75vh;
  max-height: 600px;
  width: 100%;
  object-fit: cover;
}

.service-banner2 {
  position: relative;
  height: 50vh;
  max-height: 650px;
  width: 100%;
  object-fit: cover;
}

.notfound-banner {
  position: relative;
  height: 100%;
  width: 100%;
}

/* Mobile settings */
@media only screen and (max-width: 600px) {
  .banner {
    height: 90vh;
    max-height: 800px;
  }

  .appointment-card-container {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .banner .banner-text {
    top: auto;
    bottom: 2%;
  }

  .footerLeft {
    text-align: center;
  }
  .footerRight {
    text-align: center;
  }

  .service-banner {
    height: 650px;
    max-height: 650px;
  }
}
